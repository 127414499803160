import React from "react"
import Title from "../components/Title"
import { GrMailOption } from "react-icons/Gr"
import SEO from "../components/Seo"

const contact = () => {
  return (
    <>
      <SEO title="Kentaro Takahira" />

      <main className="pages">
        <section className="section projects">
          <Title title="Contact" />

          <div className="section-center about-center">
            <article className="contact-container email">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScCDwVj5SNR6TWXzYT_grIxW2_Jy27I7SZkoSwnQ465SxfISQ/viewform?embedded=true"
                width="640"
                height="900"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                className="googleForm"
              >
                Loading…
              </iframe>
              {/* <h4>下記のアイコンからメールの送付が可能です。</h4>
              <h4>ご連絡おまちしております。</h4>

              <address className="email-icon">
                <a href="mailto:kentaro.takahira@gmail.com">
                  {<GrMailOption></GrMailOption>}
                </a>
              </address> */}
            </article>
          </div>
        </section>
      </main>
    </>
  )
}

export default contact
